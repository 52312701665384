import React from "react";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
  {
    original: 'images/gal/t1.jpg.webp',
    thumbnail: 'images/gal/t1_mic.jpg.webp',
  },
  {
    original: 'images/gal/t2.jpg.webp',
    thumbnail: 'images/gal/t2_mic.jpg.webp',
  },
  {
    original: 'images/gal/t3.jpg.webp',
    thumbnail: 'images/gal/t3_mic.jpg.webp',
  },
    {
    original: 'images/gal/t4.jpg.webp',
    thumbnail: 'images/gal/t4_mic.jpg.webp',
  },
    {
    original: 'images/gal/t5.jpg.webp',
    thumbnail: 'images/gal/t5_mic.jpg.webp',
  },
    {
    original: 'images/gal/t6.jpg.webp',
    thumbnail: 'images/gal/t6_mic.jpg.webp',
  },
];

export default function Gallery() {
  return (
  <div className="container flex px-6 py-24 mx-auto space-y-12 md:py-12 md:flex-row md:space-y-0">
    <ImageGallery items={images} />
   </div>
  );
}
