import React from "react";

export default function Features() {
  return (
    <section id="features" className="bg-white border-b py-8">
      {/* Flex container */}
      <div className="container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row">

        {/* What's different */}
        <div className="flex flex-col space-y-12 md:w-1/2">
          <h2 className="max-w-md text-4xl font-bold text-center md:text-left">
            Ce ste un Cigar Bar?
          </h2>
          <p className="max-w-sm text-center text-darkGrayishBlue md:text-left">
           Este deja cunoscut faptul ca cele mai bune conversatii sunt insotite si de un preparat deosebit. Indiferent de preferintele invitatilor, un cocktail bar luminat si echipat dupa ultimele standarde internationale reprezinta una din zonele centrale de networking si socializare ale evenimentului dumneavoastra. Fie ca vorbim de petreceri private, nunti, botezuri, majorate sau alte evenimente barul mobil a devenit un ‘Must Have’ al celor mai memorabile petreceri.
          </p>
        </div>

        {/* Numbered list */}
        <div className="flex flex-col space-y-8 md:w-1/2">
          {/* List item #1 */}
          <div className="flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row">
            {/* Heading */}
            <div className="rounded-l-full bg-brightRedSupLight md:bg-transparent">
              <div className="flex items-center space-x-2">
                <div className="px-4 py-2 text-white rounded-full md:py-1 bg-brightRed">
                  01
                </div>
                <h3 className="text-base font-bold md:mb-4 md:hidden">
                  Track company-wide progress
                </h3>
              </div>
            </div>

            {/* Display only ABOVE md */}
            <div>
              <h3 className="hidden mb-4 text-lg text-left font-bold md:block">
                Track company-wide progress
              </h3>
              <p className="text-darkGrayishBlue text-left">
Invitatii dumneavoastra se vor putea bucura de servicii de calitate executate de barmani profesionisti cu experienta indelungata in domeniul Food & Beverage inzestrati cu pasiune, seriozitate, intelegere, atentie la detalii, dar si la nevoile schimbatoare ale clientelei.
              </p>
            </div>
          </div>

          {/* List item #2 */}
          <div className="flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row">
            {/* Heading */}
            <div className="rounded-l-full bg-brightRedSupLight md:bg-transparent">
              <div className="flex items-center space-x-2">
                <div className="px-4 py-2 text-white rounded-full md:py-1 bg-brightRed">
                  02
                </div>
                <h3 className="text-base font-bold md:mb-4 md:hidden">
                  Advanced built-in reports
                </h3>
              </div>
            </div>

            {/* Display only ABOVE md */}
            <div>
              <h3 className="hidden mb-4 text-lg text-left font-bold md:block">
                Advanced built-in reports
              </h3>
              <p className="text-darkGrayishBlue text-left">
Echipa noastra va pune la dispozitie o gama extinsa de preparate alcoolice, non-alcoolice, shot-uri, long drinks sau preparate clasice realizate in functie de preferintele clientilor. Sa nu uitam nici de preparatele care contin elemente-surpriza ce vor face deliciul invitatilor dumneavoastra.
              </p>
            </div>
          </div>

          {/* List item #3 */}
          <div className="flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row">
            {/* Heading */}
            <div className="rounded-l-full bg-brightRedSupLight md:bg-transparent">
              <div className="flex items-center space-x-2">
                <div className="px-4 py-2 text-white rounded-full md:py-1 bg-brightRed">
                  03
                </div>
                <h3 className="text-base font-bold md:mb-4 md:hidden">
                  Everything you need in one place
                </h3>
              </div>
            </div>

            {/* Display only ABOVE md */}
            <div>
              <h3 className="hidden mb-4 text-lg text-left font-bold md:block">
                Everything you need in one place
              </h3>
              <p className="text-darkGrayishBlue text-left">
Cu un bar mobil, evenimentul dumneavoastra va avea un punct de interes pentru invitati. Astfel, ei vor putea socializa, invata lucruri interesante despre preparatele lor favorite si vor putea degusta o varietate de cocktail-uri mai mult sau mai putin complicate. In plus, intotdeauna este distractiv sa urmaresti in actiune un barman profesionist care mixeaza la mililitru fiecare ingredient pentru un rezultat intotdeauna de exceptie
              </p>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
}
