import React from "react";

export default function Footer() {
  return (
    <footer className="bg-veryDarkBlue">
      {/* Flex container */}
      <div className="container flex flex-col-reverse justify-between px-6 py-10 mx-auto space-y-8 md:flex-row md:space-y-0">
        {/* Container for Logo & social media */}
        <div className="flex flex-col-reverse items-center justify-between space-y-12 md:flex-col md:space-y-0 md:items-start">
          <div className="mx-auto my-6 text-center text-white md:hidden">
            Copyright &copy; 2022, All Rights Reserved
          </div>
          {/* Logo */}
          <div>
	            <a className="h-8 text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl" href="#">
            <svg className="h-8 fill-current inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.005 512.005">
              <rect fill="#2a2a31" x="16.539" y="425.626" width="479.767" height="50.502" transform="matrix(1,0,0,1,0,0)" />
              <path className="plane-take-off"
                d=" M 510.7 189.151 C 505.271 168.95 484.565 156.956 464.365 162.385 L 330.156 198.367 L 155.924 35.878 L 107.19 49.008 L 211.729 230.183 L 86.232 263.767 L 36.614 224.754 L 0 234.603 L 45.957 314.27 L 65.274 347.727 L 105.802 336.869 L 240.011 300.886 L 349.726 271.469 L 483.935 235.486 C 504.134 230.057 516.129 209.352 510.7 189.151 Z "
              />
            </svg>
            Tabacco Bar
          </a>

          </div>
          {/* Social media */}
          <div className="flex justify-center space-x-4">
            {/* Link 1 */}
            <a href="">
              <img src="images/icon-facebook.svg" className="h-8" alt="" />
            </a>
            {/* Link 2 */}
            <a href="">
              <img src="images/icon-youtube.svg" className="h-8" alt="" />
            </a>
            {/* Link 3 */}
            <a href="">
              <img src="images/icon-twitter.svg" className="h-8" alt="" />
            </a>
            {/* Link 4 */}
            <a href="">
              <img src="images/icon-pinterest.svg" className="h-8" alt="" />
            </a>
            {/* Link 5 */}
            <a href="">
              <img src="images/icon-instagram.svg" className="h-8" alt="" />
            </a>
          </div>
        </div>

        {/* List container */}
        <div className="flex justify-around space-x-32">
          <div className="flex flex-col space-y-3 text-white">
            <a href="#" className="hover:text-brightRed">
              Acasa
            </a>
            <a href="#" className="hover:text-brightRed">
              Evenimente
            </a>
            <a href="#" className="hover:text-brightRed">
              Concept
            </a>
            <a href="#" className="hover:text-brightRed">
              Despre
            </a>
          </div>
          <div className="flex flex-col space-y-3 text-white">
            <a href="#" className="hover:text-brightRed">
              Oferte
            </a>
            <a href="#" className="hover:text-brightRed">
              Termeni si Contitii
            </a>
            <a href="#" className="hover:text-brightRed">
              Politica de confidentialitate
            </a>
          </div>
        </div>

        {/* Input container */}
        <div className="flex flex-col justify-between">


          <div className="hidden text-white md:block">
            Copyright &copy; 2022, All Rights Reserved
          </div>
        </div>
      </div>
    </footer>
  );
}
